* {
  font-family: 'Exo', sans-serif;
  @apply text-black;
}

.wrapper {
  @apply container px-4;
}

.home {
  &-category-banner {
    @apply flex flex-wrap justify-center;

    &__item {
      @apply bg-gray-500 mx-6 mb-10 flex justify-center items-center rounded-lg shadow-md shadow-slate-700;
      @apply lg:mx-10 lg:mb-20;

      @media (max-width: 1023px) {
        width: calc(33% - 5rem);
      }

      @media (min-width: 1024px) {
        width: 340px;
        height: 400px;
      }
      p {
        @apply text-2xl tracking-wider font-black;
        @apply lg:text-5xl;
      }
    }
  }

  &__abouts {
    @apply flex mb-14;

    &-img {
      @apply w-1/2;

      img {
        @apply max-w-full;
      }
    }

    &-text {
      @apply w-1/2 ml-10;

      h3 {
        @apply text-5xl mb-8 font-medium;
      }

      p {
        @apply tracking-widest font-light;
      }
    }
  }

  &__policies {
    @apply mb-6;
    @apply lg:mb-24;

    &-main-title {
      h3 {
        @apply text-2xl mb-14 font-medium text-center;
        @apply lg:text-4xl;
      }
    }

    &-items {
      @apply flex flex-wrap justify-between relative;
      @apply sm:justify-evenly;
    }

    &-item {
      @apply w-72 h-80 bg-white flex items-center flex-col p-4 rounded-lg justify-center shadow-lg shadow-gray-900 mb-10 transition-all duration-500 ease-in-out;
      @apply xl:mb-0;
      @apply hover:bg-[#976C57] hover:shadow-gray-800 hover:-top-5 hover:relative hover:cursor-default hover:border hover:border-white hover:delay-100;

      .icon {
        @apply mb-6;
      }

      .title {
        @apply mb-6 font-medium text-xl text-black text-center;
      }

      .desc {
        @apply text-center text-sm text-black opacity-0 invisible transition-all duration-300 ease-out h-0;
      }

      &:hover {
        .desc {
          @apply opacity-100 visible h-full;
        }
      }
    }

    i {
      @apply text-6xl text-black;
    }
  }
}

.our-working-zones {
  @apply py-10;

  .main-title {
    @apply text-3xl mb-10 text-center;
    @apply lg:text-5xl;
  }

  .main-desc {
    @apply mb-5 text-center;
  }

  &__items {
    @apply flex flex-wrap;
  }

  &__item {
    @apply flex flex-col m-4 p-8 border-4 border-theme-dark-blue transition-colors duration-200 ease-in;
    @apply hover:border-theme-gold-100 hover:bg-theme-dark-blue hover:shadow-xl hover:shadow-slate-300;

    @screen md {
      width: calc(50% - 32px);
    }

    @screen lg {
      width: calc(33.3% - 32px);
    }

    &:hover {
      .icon i,
      .title h3,
      .description {
        @apply text-theme-gold-100;
      }
    }

    .icon {
      @apply text-center text-6xl;
    }

    .title {
      h3 {
        @apply text-center text-2xl mb-5 text-theme-dark-blue;
      }
    }

    .description {
      @apply text-center text-theme-dark-blue;
    }
  }
}

.teams {
  @apply bg-[url('/images/teams-bg.jpg')] py-20 bg-no-repeat bg-center bg-cover;

  &-title {
    h2 {
      @apply text-center text-3xl mb-10 text-white;
      @apply lg:text-5xl;
    }
  }

  &-item {
    @apply flex flex-col;
    @apply lg:flex-row;
  }

  &-left {
    @apply mb-5;
    @apply lg:w-1/3;
  }

  &-right {
    @apply flex flex-col justify-between;
    @apply lg:w-2/3 lg:pl-16 lg:pr-36;
  }

  &-name {
    h2 {
      @apply text-2xl mb-5 text-slate-300 font-normal;
    }
  }

  &-cv {
    p {
      @apply text-slate-300 font-light leading-9 mb-3;

      &.mobile {
        @apply block;
        @apply lg:hidden;
      }

      &.desktop {
        @apply hidden;
        @apply lg:block;
      }
    }
  }

  .splide__pagination__page {
    @apply opacity-50;

    &.is-active {
      @apply bg-theme-gold-100 opacity-100;
    }
  }

  .splide__arrow {
    @apply bg-transparent top-1/4;
    @apply lg:top-1/2;

    svg {
      @apply fill-theme-gold-300 h-8 w-8 drop-shadow;
    }
  }

  .splide__pagination {
    @apply -bottom-8;
  }
}

.articles {
  &-title {
    @apply text-center text-3xl my-10 text-theme-dark-blue;
    @apply lg:text-5xl;
  }

  &-wrapper {
    @apply bg-[#F8F8F8] px-5 pt-10 pb-5 flex flex-wrap justify-between;
  }

  &-item {
    @apply w-full transition-all;
    @apply hover:shadow-xl hover:shadow-gray-300;
    @apply lg:w-[47%];


    &__link {
      @apply flex flex-col p-5;
      @apply lg:flex-row;
    }

    &-left {
      @apply mb-5;
      @apply lg:mb-0 lg:w-1/3;

      img {
        @apply object-cover w-full h-[205px];
      }
    }

    &-right {
      @apply flex flex-col;
      @apply lg:pl-5 lg:w-2/3;
    }

    &__title {
      h2 {
        @apply text-xl font-medium mb-3 font-oswald;
      }
    }

    &__desc {
      @apply text-sm font-light mb-3 tracking-tighter;
    }

    &__date {
      @apply text-xs font-extralight text-gray-500 font-oswald;
    }
  }

  &-button {
    @apply flex justify-center my-5;
  }
}

.border-button {
  a,
  button {
    @apply border-4 px-10 py-3 text-sm font-light transition-all duration-300 inline-block;
  }

  &.-white {
    a,
    button {
      @apply border-stone-200 text-slate-200;
      @apply hover:bg-stone-200 hover:text-theme-dark-blue hover:shadow-sm hover:shadow-slate-600;
    }
  }

  &.-blue {
    a,
    button {
      @apply border-theme-dark-blue text-theme-dark-blue;
      @apply hover:bg-theme-dark-blue hover:text-stone-200 hover:shadow-sm hover:shadow-slate-600;
    }
  }
}
