@font-face {
    font-family: 'Oswald';
    src: local('Oswald Bold'), local('Oswald-Bold'),
        url('Oswald-Bold.woff2') format('woff2'),
        url('Oswald-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
        url('Oswald-SemiBold.woff2') format('woff2'),
        url('Oswald-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
        url('Oswald-ExtraLight.woff2') format('woff2'),
        url('Oswald-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald Medium'), local('Oswald-Medium'),
        url('Oswald-Medium.woff2') format('woff2'),
        url('Oswald-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald Light'), local('Oswald-Light'),
        url('Oswald-Light.woff2') format('woff2'),
        url('Oswald-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald Regular'), local('Oswald-Regular'),
        url('Oswald-Regular.woff2') format('woff2'),
        url('Oswald-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

