.slider {
  @apply bg-[url('/images/header-bg.jpg')] h-[50vh] min-h-[370px] bg-no-repeat flex items-center justify-center flex-col bg-cover bg-center;

  @screen lg {
    height: calc(100vh - 120px);
  }

  &-title {
    @apply text-lg text-center text-theme-gold-100 font-normal my-4 w-3/4 mx-auto;
    @apply lg:text-5xl lg:text-center lg:font-medium lg:mb-8;

    &.--image {
      @apply text-left ml-10 my-2 text-base;
      @apply lg:text-4xl;
    }
  }

  &-desc {
    @apply text-base text-center font-light text-white w-4/5 mx-auto;
    @apply lg:text-2xl;

    &.--image {
      @apply text-left ml-10 text-sm;
      @apply lg:text-xl;
    }
  }

  &-button {
    @apply flex mt-5;
    @apply lg:mt-10;

    &.--center {
      @apply justify-center;
    }

    &.--left {
      @apply justify-start ml-10;
    }

    a {
      @apply border border-gray-50 px-4 py-2 text-gray-200 rounded-full transition-all duration-200;
      @apply hover:bg-gray-200 hover:text-gray-700;
    }

    &.--image {
      @apply text-left text-sm;
    }
  }

  &-left {
    @apply inline-block w-2/5;
  }

  &-right {
    @apply inline-block;
    @apply lg:pl-10;

    @screen lg {
      width: calc(60% - 1rem);
    }
  }

  .splide__pagination {
    &__page {
      @apply w-10 rounded-md h-[3px] mx-2;
      @apply lg:w-20;

      &.is-active {
        @apply scale-100 h-2;
      }
    }
  }

  .splide__arrow--prev {
    @apply left-2;
    @apply lg:left-4;
  }

  .splide__arrow--next {
    @apply right-2;
    @apply lg:right-4;
  }

  .splide__arrow {
    @apply bg-transparent;

    svg {
      @apply fill-gray-100;
      @apply lg:w-8 lg:h-8;
    }
  }
}
