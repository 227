@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply mx-auto;
  }
}

@import 'fonts';
@import 'index';
@import 'header';
@import 'footer';
@import '@fancyapps/ui/dist/fancybox.css';
@import 'page';
@import 'slider';
@import '@splidejs/splide/dist/css/splide.min';
@import 'article';
@import 'contact';
