.article {
  @apply flex justify-between items-start flex-col;
  @apply lg:flex-row;

  .left-article {
    @apply flex flex-wrap flex-1 font-proxima #{!important};
    @apply lg:pr-16;

    h1,h2,h3,h4,h5,h6 {
      @apply text-theme-dark-blue font-medium mt-12 mb-6 ml-auto font-proxima #{!important};
    }

    p {
      @apply mb-6 #{!important};
    }

    p, span {
      @apply text-gray-700 leading-7 font-proxima ml-auto text-base #{!important};
    }

    b, strong {
      @apply text-theme-dark-blue leading-7 ml-auto #{!important};
    }

    h1 {
      @apply text-4xl;
    }

    h2 {
      @apply text-3xl;
    }

    h3 {
      @apply text-2xl;
    }

    h4 {
      @apply text-xl;
    }

    h5, h6 {
      @apply text-lg;
    }

    .article-item {
      .day,
      .mont,
      .year {
        @apply text-white text-sm text-center mr-auto #{!important};
      }
    }
  }

  &-top-image {
    @apply mb-8;
  }

  &-item {
    @apply m-5 p-2 relative transition-all w-full;
    @apply hover:bg-gray-100 hover:drop-shadow-md;

    @screen lg {
      width: calc(50% - 40px);
    }

    &__image {
      @apply mb-4;
    }

    &__title {
      @apply text-base;
    }

    &__date {
      @apply absolute -top-4 -left-4 flex flex-col z-[1] bg-theme-dark-blue p-2 w-20;

      .day,
      .mont,
      .year {
        @apply text-white text-sm text-center #{!important};
      }
    }
  }

  &-paginate {
    @apply mt-10 px-5;

    @screen lg {
      width: calc(100% - 288px);
    }
  }
}
