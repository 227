.page {
  &__wrapper {
    @apply container;
  }

  &__content {
    @apply py-2 px-4 min-h-[400px] bg-white -mt-6 relative shadow-md mb-12;
    @apply lg:py-12 lg:px-12;

    &-left {
      @apply w-full mb-5;
      @apply lg:w-1/2;
    }

    &-right {
      @apply w-full;
      @apply lg:w-1/2 lg:pl-20;

      h3 {
        @apply mb-6 text-3xl;
      }

      p {
        @apply mb-5;
      }
    }

    &-slider {
      .splide__progress__bar {
        height: 2px;
        background: #eee;
        opacity: 0.3;
      }

      .splide__pagination__page {
        @apply opacity-50 bg-white shadow-lg;

        &.is-active {
          opacity: 1;
        }
      }
    }

    &-thumbnail-slider {
      @apply mt-3;

      .splide__slide {
        opacity: 0.3;
      }

      .splide__slide.is-active {
        opacity: 1;
      }
    }
  }

  &__top {
    @apply py-20 bg-gradient-to-r from-theme-dark-blue to-blue-800 relative;

    &-bg {
      @apply absolute w-80 right-5 top-1/2 opacity-10 -translate-y-1/2;
    }
  }

  &__title {
    h1 {
      @apply text-2xl text-gray-200 mb-4;
      @apply lg:text-4xl lg:max-w-4xl;
    }
  }

  &__breadcrumb {
    @apply relative z-[5];

    a, span {
      @apply font-light text-lg text-gray-400 transition-colors duration-200 ease-in;
      @apply lg:text-xs;
    }

    a {
      @apply hover:text-gray-50;
    }
  }

  p {
    @apply leading-7;
  }

  .our-working-zone {
    @apply flex items-start flex-col;
    @apply lg:flex-row;
  }

  .left-content {
    @apply mr-3 p-4 w-full;

    @screen lg {
      width: calc(100% - 300px);
    }

    p {
      @apply leading-loose #{!important};
    }

    ul {
      @apply list-inside
    }

    li {
      @apply list-disc leading-loose;
    }
  }

  .right-side-menu {
    @apply w-full p-8 shadow-md border-t-4 border-theme-dark-blue mt-5;
    @apply lg:w-72 lg:min-h-[250px] lg:mt-0;

    h3 {
      @apply font-medium mb-3 relative text-lg;

      &::before {
        @apply w-1 h-7 bg-theme-dark-blue absolute inline-block content-[''] -ml-2;
      }
    }

    &__item {
      @apply rounded-md;

      a {
        @apply py-2 px-3 mb-1 transition-colors duration-300 ease-in-out block rounded-md;
        @apply hover:bg-theme-dark-blue hover:text-white;
      }

      &.active {
        @apply bg-theme-dark-blue text-white;
      }
    }
  }

  .team {
    @apply flex flex-wrap justify-center;

    &-item {
      @apply mx-2 mt-5 mb-24 relative;
      @apply lg:mx-5;

      width: calc(100% - 40px);

      @screen md {
        width: calc(50% - 40px);
      }

      @screen xl {
        width: calc(33.3% - 40px);
      }

      &__info {
        @apply absolute text-center p-2 -bottom-20 bg-white shadow-md left-3 right-3;
        @apply lg:p-5 lg:left-10;

        &:hover {
          .desc {
            @apply opacity-100 visible max-h-24;
          }
        }
      }

      .name {
        @apply mb-2 text-theme-gold-300;
      }

      .mail {
        @apply mb-2 overflow-x-auto;

        a {
          @apply font-proxima text-sm font-light transition-all text-neutral-500;
          @apply hover:text-neutral-900;
        }
      }

      .desc {
        @apply mb-4 text-sm opacity-0 invisible max-h-0 duration-300 ease-out-expo transition-[max-height,opacity] font-proxima text-slate-500 relative overflow-hidden;

        &::before {
          @apply content-[''] w-1/5 h-[1px] bg-theme-dark-blue block mx-auto my-4;
        }
      }

      .social {
        i {
          @apply text-neutral-300 text-lg transition-colors mx-1;
          @apply hover:text-theme-gold-100;
        }
      }
    }

    &-detail {
      &__top {
        @apply flex flex-wrap justify-center mb-5;
        @apply lg:flex-nowrap lg:justify-start;
      }

      &__bottom {
        @apply mt-10 mb-5 leading-loose;

        h2 {
          @apply first-letter:text-3xl first-letter:font-normal font-proxima font-semibold;
        }
      }

      &__content {
        @apply drop-shadow-xl w-full p-4 bg-gradient-to-br to-white from-gray-200 border border-gray-200 h-full;
        @apply lg:p-20 lg:-translate-x-14 lg:translate-y-14 lg:w-1/2 lg:max-w-lg;

        h3 {
          @apply text-2xl mb-5 text-theme-dark-blue;
        }

        p {
          @apply mb-5 text-base;
        }

        .mail {
          @apply text-gray-600 transition-colors duration-300 ease-in-out;
          @apply hover:text-theme-dark-blue;
        }

        .social {
          i {
            @apply text-2xl mr-1 text-gray-500 transition-colors duration-300 ease-in-out;
            @apply hover:text-theme-gold-300;
          }
        }


      }
    }
  }
}

.divider {
  @apply my-2 w-20 h-[2px] bg-theme-dark-blue block;
}

.carousel__button svg, .fancybox__button--close svg {
  stroke: white !important;
}

.fancybox__caption,
.fancybox__counter,
.fancybox__counter span {
  color: white !important;
}

.galery {
  @apply flex flex-wrap justify-center gap-6;

  &-item {
    @apply w-[220px] h-[200px] object-cover object-center;
  }
}

