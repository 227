@font-face {
    font-family: 'Exo';
    src: url('Exo-Regular.eot');
    src: local('Exo Regular'), local('Exo-Regular'),
        url('Exo-Regular.eot?#iefix') format('embedded-opentype'),
        url('Exo-Regular.woff2') format('woff2'),
        url('Exo-Regular.woff') format('woff'),
        url('Exo-Regular.ttf') format('truetype'),
        url('Exo-Regular.svg#Exo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Black.eot');
    src: local('Exo Black'), local('Exo-Black'),
        url('Exo-Black.eot?#iefix') format('embedded-opentype'),
        url('Exo-Black.woff2') format('woff2'),
        url('Exo-Black.woff') format('woff'),
        url('Exo-Black.ttf') format('truetype'),
        url('Exo-Black.svg#Exo-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Medium.eot');
    src: local('Exo Medium'), local('Exo-Medium'),
        url('Exo-Medium.eot?#iefix') format('embedded-opentype'),
        url('Exo-Medium.woff2') format('woff2'),
        url('Exo-Medium.woff') format('woff'),
        url('Exo-Medium.ttf') format('truetype'),
        url('Exo-Medium.svg#Exo-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Light.eot');
    src: local('Exo Light'), local('Exo-Light'),
        url('Exo-Light.eot?#iefix') format('embedded-opentype'),
        url('Exo-Light.woff2') format('woff2'),
        url('Exo-Light.woff') format('woff'),
        url('Exo-Light.ttf') format('truetype'),
        url('Exo-Light.svg#Exo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

