@font-face {
    font-family: 'Proxima';
    src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
        url('ProximaNova-Extrabld.woff2') format('woff2'),
        url('ProximaNova-Extrabld.woff') format('woff');
    font-weight: bolder;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
        url('ProximaNovaT-Thin.woff2') format('woff2'),
        url('ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

